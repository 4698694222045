$google: #de5246;
$discord: #7289da;
$reddit: #ff5700;
$apple: white;
$twitch: #6441a5;
$twitter: #1da1f2;

$socials: "google" $google, "discord" $discord, "reddit" $reddit, "apple" $apple,
  "twitch" $twitch, "twitter" $twitter;

@each $name, $color in $socials {
  .color-#{$name} {
    color: $color !important;
  }

  .background-#{$name} {
    background: $color !important;
  }
}

.background-apple--alt {
  background: black !important;
}
