@import "./constants.scss";
@import "./App.scss";
@import "./login.scss";
@import "./containers.scss";
@import "./register.scss";
@import "./nav.scss";
@import "./clients.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;600&display=swap");

body {
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.divider {
  width: 100%;
  height: 10px;
  border-bottom: 1px solid black;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.divider--text {
  font-size: 14px;
  background-color: white;
  padding: 0 10px;
}

.search__options {
  position: absolute;
  width: 100% !important;
  padding: 10px 0px;
  top: calc(100% - 22px);
  z-index: 1;
  background: white;
}

.search__empty {
  width: 100%;
  height: 100%;
  padding: 30px 0px;
  font-size: 24px;
  opacity: 0.4;
  display: flex !important;
  justify-content: center;
  align-items: center;
  max-height: 300px;
  overflow-y: scroll;
}
