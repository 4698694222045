.register__header {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-weight: lighter;
  svg {
    font-size: 60px;
    margin-right: 24px;
  }
}

.register__form {
  padding: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.register__form--full {
  width: 100%;
}

.register__input {
  margin: 10px 0px !important;
  width: 100%;
}

.register__submit {
  width: 100%;
  margin-top: 15px !important;
  padding: 15px 0px !important;
}

.register__verify {
  width: 100%;
  font-size: 16px;
  margin-bottom: 20px;
}

.register__back {
  display: flex;
  align-items: center;
  width: 25%;
  position: absolute;
  top: 15px;
  left: 15px;

  svg {
    margin-right: 10px;
  }

  &:hover {
    cursor: pointer;
    color: dodgerblue;
  }
}
