.container__page {
  padding-top: 40px !important;
  position: relative;
}

.container__outercentered {
  width: 100%;
  height: 100vh;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.container__innercentered {
  position: relative;
  width: 40%;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 30px 46px 30px 46px;
  border-radius: 4px;
  min-width: 400px;
}

.container__card {
  position: relative;
  width: calc(100% - 60px);
  padding: 30px;
}

.container__header {
  margin-bottom: 20px !important;
}
