.client__card {
  padding: 30px 20px !important;
  text-decoration: none !important;
  color: black !important;
  transition: all 0.2s ease-in;
  margin-bottom: 20px;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}

.client__card--new {
  justify-content: center !important;
  align-items: center !important;
  font-size: 18px !important;
  width: 100%;
  color: white !important;
  text-transform: none !important;
  svg {
    margin-right: 12px;
  }
}

.client__cardname {
  font-size: 20px !important;
  text-decoration: none !important;
  flex-grow: 1;
}

.client__cardname--id {
  font-size: 12px !important;
  text-decoration: none !important;
  flex-grow: 1;
  opacity: 0.8;
  font-style: italic;
}

.client__cardaction {
  color: black !important;
  margin-left: 10px !important;
}

.client__cardaction--delete {
  color: #d9534f !important;
}

.client__delete {
  position: absolute !important;
  top: 55px;
  right: -10px;
}

.client__key {
  margin-top: 20px;
  overflow-wrap: break-word;
  padding: 20px;
  background: rgba(123, 123, 123, 0.1);
  border-radius: 2px;
}

.client__keyactions {
  position: absolute !important;
  top: 35px;
  right: 20px;
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.client__socials {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  grid-gap: 10px;
}

.client__socialcontainer {
  width: 100%;
}

.client__socialcontainer--new {
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  flex-direction: column;
  font-size: 50px !important;
  text-transform: none !important;
  min-height: 285px;
}

.client__socialtypes {
  display: grid;
  grid-template-columns: repeat(auto-fill, 40px);
  grid-gap: 10px;
  margin-bottom: 20px;
}

.client__socialbutton {
  width: 40px !important;
  height: 40px !important;
  opacity: 0.5;
  font-size: 20px !important;

  &:hover {
    opacity: 1;
  }
}

.client__socialbutton--selected {
  color: white !important;
  // background: black !important;
  border-radius: 50% !important;
  opacity: 1;
}

.client__searchoption {
  padding: 10px;

  &:hover {
    cursor: pointer;
    background: rgba(123, 123, 123, 0.1);
  }
}

.client__connectedclient {
  width: 100%;
  display: grid !important;
  grid-template-columns: auto 40px;
  padding: 5px 10px;
  border-radius: 2px;

  &:hover {
    background: rgba(200, 200, 200, 0.05);
  }
}

.client__connectedclientinfo {
  height: 100%;
  display: flex;
  // justify-content: center;
  align-items: center;
}

.client__connectedclientsempty {
  width: 100%;
  padding: 40px 20px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  opacity: 0.5;
}
