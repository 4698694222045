.nav__container {
  background: transparent !important;
  color: black !important;
  box-shadow: none !important;
  padding: 10px 30px;
}

.nav__toolbar {
  position: relative;
}

.nav__link {
  height: 100%;
  font-size: 1.5em;
  color: black !important;
  &:hover {
    color: dodgerblue !important;
    cursor: pointer;
  }
}

.nav__profile {
  position: absolute;
  right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav__username {
  font-size: 14px;
  padding: 6px 16px;
  user-select: none;
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
}
