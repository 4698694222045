.oauth__container {
  button {
    width: 80%;
    margin: 8px 0px;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
  }
}

.oauth__header {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  font-size: 40px;
  margin-bottom: 30px;
  font-weight: lighter;
  svg {
    font-size: 60px;
    margin-right: 12px;
  }
}

.oauth__divider {
  margin: 20px 0px;
}

.login__oauthselection {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.login__oauthbutton {
  width: 100% !important;
  margin: 10px 0px !important;
  padding: 10px 0px !important;
  text-transform: none !important;
  font-size: 16px !important;

  svg {
    margin-left: 12px;
  }
}

.login__subtext {
  width: 100%;
  font-size: 14px;
  color: dodgerblue;
  margin-top: 5px;
  &:hover {
    cursor: pointer;
  }
}

.teamliquidlogo{
  width: 100px;
  margin-bottom: 16px;
  background-image: url(../TL_Logo_Dark.png);
}